import React from "react";
import Effi from '../images/home-page/logo/effi-logo.png';

const TrustedClients = (props) => {
  return (
    <div className="container">
        <div className="row">
            <div className="col-12 col-md-4 col-lg-4 p-0">
                <div className="image-wrap img-mandeep"></div>
            </div>
            <div className="col-12 col-md-8 col-lg-8">
                <div className="client-comment">
                    <div>
                        <p>“I have been working with iTelaSoft since Jan 2020, and they have added value to our mortgage broker platform by enhancing the platform’s features and allocating a dedicated team. They are also providing project coordination as a service to us, which is one of the reasons for this engagement to be successful, thanks to their commitment and corporative approach. As a client, we are very much satisfied with their work and will definitely recommend them for a similar engagement.”
                        </p> 
                    </div>
                    <div className="client-dets">
                        <div>
                            <h6>Mandeep Sodhi</h6>
                            <p>Effi</p>
                        </div>
                        <div>
                            <img className="" alt="effi-logo" src={Effi} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default TrustedClients;
